import country from '@/config/countries.js';

export const TENANT_IDS = {
  BM_DEMO_GLOBAL: 'bmdemo-global',
  BM_DEMO_UK: 'bmdemo-uk',
  BM_DEMO_US: 'bmdemo-us',
  GLS_AT: 'gls-at',
  GLS_BE: 'gls-be',
  GLS_CA: 'gls-ca',
  GLS_CZ: 'gls-cz',
  GLS_DE: 'gls-de',
  GLS_ES: 'gls-es',
  GLS_FR: 'gls-fr',
  GLS_HR: 'gls-hr',
  GLS_HU: 'gls-hu',
  GLS_IE: 'gls-ie',
  GLS_IT: 'gls-it',
  GLS_NL: 'gls-nl',
  GLS_PL: 'gls-pl',
  GLS_RO: 'gls-ro',
  GLS_RS: 'gls-rs',
  GLS_SI: 'gls-si',
  GLS_SK: 'gls-sk',
  GLS_US: 'gls-us',
  ROYALMAIL_UK: 'royalmail-uk',
  ROYALMAIL_UK_TEST: 'royalmail-uk-test'
};

const tenant = (id, country, name, config) => ({
  ...{ id, name, country },
  ...config
});

export default Object.fromEntries(
  [
    tenant(TENANT_IDS.BM_DEMO_GLOBAL, country.US, 'Bettermile Demo Global'),
    tenant(TENANT_IDS.BM_DEMO_UK, country.UK, 'Bettermile Demo UK'),
    tenant(TENANT_IDS.BM_DEMO_US, country.US, 'Bettermile Demo US'),
    tenant(TENANT_IDS.GLS_AT, country.AT, 'GLS Austria'),
    tenant(TENANT_IDS.GLS_BE, country.BE, 'GLS Belgique', {
      stopsSteps: [5, 10, 20, 30, 50, 70, 90],
      disableStopsComponent: true
    }),
    tenant(TENANT_IDS.GLS_CA, country.CA, 'GLS Canada'),
    tenant(TENANT_IDS.GLS_CZ, country.CZ, 'GLS Czech Republic'),
    tenant(TENANT_IDS.GLS_DE, country.DE, 'GLS Germany'),
    tenant(TENANT_IDS.GLS_ES, country.ES, 'GLS Spain'),
    tenant(TENANT_IDS.GLS_FR, country.FR, 'GLS France'),
    tenant(TENANT_IDS.GLS_HR, country.HR, 'GLS Croatia'),
    tenant(TENANT_IDS.GLS_HU, country.HU, 'GLS Hungary', {
      resetGdpr: true,
      disableToAddress: true,
      stopsSteps: [5, 10, 20, 30, 50, 70, 90]
    }),
    tenant(TENANT_IDS.GLS_IE, country.IE, 'GLS Ireland', {
      postCodeTextInput: true
    }),
    tenant(TENANT_IDS.GLS_IT, country.IT, 'GLS Italia', {
      disableImprintLink: true,
      disableDepositPermission: true,
      disableDeliverToNeighbour: true,
      disableFromAddress: true,
      disableStopsComponent: true
    }),
    tenant(TENANT_IDS.GLS_NL, country.NL, 'GLS Netherlands', {
      disable5StarRating: true,
      disableContactLink: true,
      disableImprintLink: true,
      postCodeTextInput: true
    }),
    tenant(TENANT_IDS.GLS_PL, country.PL, 'GLS Poland'),
    tenant(TENANT_IDS.GLS_RO, country.RO, 'GLS Romania'),
    tenant(TENANT_IDS.GLS_RS, country.RS, 'GLS Serbia'),
    tenant(TENANT_IDS.GLS_SI, country.SI, 'GLS Slovenia'),
    tenant(TENANT_IDS.GLS_SK, country.SK, 'GLS Slovakia', {
      stopsSteps: [5, 10, 20, 30, 50, 70, 90]
    }),
    tenant(TENANT_IDS.GLS_US, country.US, 'GLS US', {
      disableTrackingId: true,
      disableImprintLink: true
    }),
    tenant(TENANT_IDS.ROYALMAIL_UK, country.UK, 'Royal Mail', {
      stopsSteps: [5, 10, 20, 30, 50, 70, 90],
      postCodeTextInput: true
    }),
    tenant(TENANT_IDS.ROYALMAIL_UK_TEST, country.UK, 'Royal Mail (QA)', {
      stopsSteps: [5, 10, 20, 30, 50, 70, 90],
      postCodeTextInput: true
    })
  ].map(tenant => [tenant.id, tenant])
);
